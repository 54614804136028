import { Injectable } from '@angular/core';
import { ValidateResultsResponseDataModel } from '@app/models/validateResult.model';

@Injectable({
  providedIn: 'root'
})
export class FetchService {

  constructor() { }

  saveUserInfo(userInfo: ValidateResultsResponseDataModel) {
    if (!userInfo) return;

    const celular = userInfo.celular || '';
    userInfo.celular = celular.substring(celular.length - 10);
    sessionStorage.setItem('userInfo_ID', JSON.stringify(userInfo));
  }
}
