import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SubjectService } from '@app/services/subject.service';
import { ToastrService } from 'ngx-toastr';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Output() handleNext: EventEmitter<any> = new EventEmitter();
  @Output() handlePrev: EventEmitter<any> = new EventEmitter();
  @Output() handleGoToStep: EventEmitter<any> = new EventEmitter();
  @Input() maxPage: number = 4;

  public steps = [
    { id: 1, description: 'Datos Personales' },
    { id: 2, description: 'Validación de identidad' },
    { id: 3, description: 'Datos básicos y de contacto' },
    { id: 4, description: 'Referencia comercial' },
  ];

  public stepState = [
    {step: 1, isShowXL: false},
    {step: 2, isShowXL: true},
    {step: 3, isShowXL: true},
    {step: 4, isShowXL: true}
  ]

  // pagination
  public transition: boolean;
  public page = 0;
  public maxActivePage = 0;
  public selectedStep: number;
  public date = new Date();
  public totalPercent: any;
  public stateTypePerson: string;

  constructor(public toastr: ToastrService, private subject: SubjectService, private tabs: TabsService) { }

  ngOnInit() {
    this.subject.stateCurrentStep$.subscribe(resp => {
      this.page = resp;
    });
    this.subject.stateTypePerson$.subscribe(resp => {
      this.stateTypePerson = resp;
    })
  }



  mouseEnter(id: number) {
    // console.log('mouse enter : ' + id + lista);
    this.selectedStep = id;
  }

  mouseLeave(id: number) {
    //  console.log('mouse leave :' + id + lista);
    this.selectedStep = undefined;
  }

  next() {
    if (this.page >= this.maxPage) {
      return;
    }
    this.transition = true;
    this.MarkForm();
    if (this.page > this.maxActivePage) {
      this.maxActivePage = this.page;
    }

    this.subject.changeStep(this.page);

  }

  prev() {
    if (this.page <= 0) {
      return;
    }
    this.page--;
    this.transition = false;
    this.MarkForm();
    this.subject.changeStep(this.page);

  }

  goToPage(i: any) {
    const legalPerson = [
      {step: 1, isShowXL: false},
      {step: 2, isShowXL: true},
      {step: 3, isShowXL: true},
      {step: 4, isShowXL: true},
    ];
    const naturalPerson = [
      {step: 1, isShowXL: false},
      {step: 2, isShowXL: false},
      {step: 3, isShowXL: true},
      {step: 4, isShowXL: true}
    ]
    
    if (i < 1) {
      this.page = i;
      this.MarkForm();
      this.subject.changeStep(this.page);
      
      if(this.stateTypePerson === "legal") {
        this.tabs.changeIsShowXL(legalPerson[i].isShowXL);
      } else {
        this.tabs.changeIsShowXL(naturalPerson[i].isShowXL);
      }
    }
  }

  MarkForm() {
    setTimeout(() => {
      // const self = this;
      // Object.keys(this.formEnviar.form.controls).forEach(function logArrayElements(control, indice) {
      //   self.formEnviar.form.controls[control].markAsTouched();
      // });
      this.toastr.warning('Todos los campos marcados con rojo son obligatorios', null, {
        timeOut: 6000
      });
    }, 500);
  }

}
