import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
} from '@angular/core';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { Department } from '@models/department.model';
import { City } from '@models/city.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@services/helper.service';
import { SubjectService } from '@services/subject.service';
import { ToastrService } from 'ngx-toastr';
import { SelectsService } from '@app/services/selects.service';
import { CommercialNaturalInformation, CommercialLegalInformation } from '@models/clientStudyNaturalRequest.model'
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-commercial-reference',
  templateUrl: './commercial-reference.component.html',
  styleUrls: ['./commercial-reference.component.scss'],
})
export class CommercialReferenceComponent implements OnInit {
  @Input() clientStudyForm: ClientStudyRequest;
  /* @Input() departments: Array<Department>; */
  @Input() updateRefCities: Subject<boolean>;
  @Input() required: boolean = true;
  

  public firstRefCities: Array<City> = [];
  public secondRefCities: Array<City> = [];
  public maxLengthNumber: number = 10;

  // Radio
  private radioSelected = 'legal_person';
  private radio = [
    { name: 'Persona Jurídica', value: 'legal' },
    { name: 'Persona Natural', value: 'natural' },
  ];

  @Input() id: number;
  
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @Output() testData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('formReference') formReference: NgForm[];
  @ViewChild('formTwoReference') formTwoReference: NgForm[];

  @Output() formCommercialReferenceValidate: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('formCommercialReference') formCommercialReference: NgForm;

  public cant = [1, 2];

  public typePerson: string;

  // Form
  public dataRadio = [
    { value: 'legal' || 'natural', }    
  ]

  public dataLegal: CommercialLegalInformation[] = [
    {
      commercialLegalSocialReason: '',
      commercialLegalDepartment: '',
      commercialLegalCity: '',
      commercialLegalTelephone: '',
      commercialLegalEmail: '',
    },
  ];

  public dataNatural: CommercialNaturalInformation[] = [
    {
      commercialNaturalFirstName: '',
      commercialNaturalSecondName: '',
      commercialNaturalFirstLastName: '',
      commercialNaturalSecondLastName: '',
      commercialNaturalDepartment: '',
      commercialNaturalCity: '',
      commercialNaturalTellphone: '',
      commercialNaturalEmail: ''
    },
  ];

  public DataSelectFake = [
    { value: 1, description: 'Opción 1' },
    { value: 2, description: 'Opción 2' },
    { value: 3, description: 'Opción 3' },
  ];

  public cities: any;
  public departments = [];
  public secondDepartments = [];
  public department: any = '';
  public selectedFirstDepartment: any = {};
  public selectedSecondDepartment: any = {};
  public secondDepartment = '';
  public municipalities = [];
  public municipallity: any = {};
  public secondMunicipallity: any = {};
  public secondMunicipalities = [];

  public counterForm: Array<any> = [''];
  
  constructor(
    @Inject(DOCUMENT) private document: any,
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    private toastr: ToastrService,
    public select: SelectsService,
    private subject: SubjectService
  ) {}

  ngOnInit() {
    this.getCities();

    this.subject.stateTypePerson$.subscribe((typePerson: string) => {
      this.typePerson = typePerson;
    })
    
    if(this.clientStudyForm.tipo_ref_1 === '') {
      this.clientStudyForm.tipo_ref_1 =  'PERSONAL' || 'COMERCIAL';
    }
    
    this.subject.stateFirstMunicipalities$.subscribe(resp => {
      this.municipalities = resp;
    });

    this.subject.stateFirstCommercialDepartment$.subscribe(department => {
      this.selectedFirstDepartment = department;
      this.department = department.ID
    });

    this.subject.stateFirstCommercialCity$.subscribe(city => {
      this.municipallity = city;
      this.clientStudyForm.lista_cuidad_ref_1 = city.ID;
    })
  }

  handleGetFirstDepartment($event) {
    const selectedDepartment = this.departments.filter(department => department.ID === this.department);
    this.selectedFirstDepartment = selectedDepartment[0]
    
    this.subject.changeFirstCommercialDepartment(this.selectedFirstDepartment);
  }

  handleGetFirstCity($event) {
    const selectedCity = this.cities.filter(city => city.ID === $event);
    this.municipallity = selectedCity[0];
    this.subject.changeFirstCommercialCity(this.municipallity);
  }

  getCities() {
    this.select.getCities().subscribe((response) => {
      this.cities = this.mapCities(response['CIUDAD-DEPARTAMENTO']);
    });
  }

  mapCities(citiesData: any[]): any[] {
    return citiesData.map(city => {
      return {
        ...city,
        ID: city.ID.toString()
      };
    });
  }

  selectDeparment(selectedDepartment: any) {
    this.spinner.show();
    this.clientStudyForm.lista_cuidad_ref_1 = "";
    this.select.getMunicipalities(selectedDepartment).subscribe(
      (municipalities: any) => {
        this.subject.changeFirstCommercialCity({ID: '', LABEL: ''})
        this.subject.changeStateFirstMunicipalities(municipalities);
        //this.municipalities = municipalities;
      }, (err) => {
        this.municipalities = [];
      }, () => {
        this.spinner.hide();
      });
  }

  addPerson($event) {
    this.testData.emit();
    const radio = {
      value: 'legal' || 'natural',
    }

    if ((this.counterForm.length >= 2)) {
      this.toastr.info("Puede agregar máximo 2 referencias.", null);
      return;
    } else {
      this.clientStudyForm.tipo_ref_2 = 'PERSONAL' || 'COMERCIAL' ;
      this.counterForm.push('');
    }
    this.handleValidateCommercialReference()
  }

  changeTypeFirstPerson() {
    this.clientStudyForm.nombre1_ref_1 = '';
    this.clientStudyForm.nombre2_ref_1 = '';
    this.clientStudyForm.apellido1_ref_1 = '';
    this.clientStudyForm.apellido2_ref_1 = '';
    this.clientStudyForm.telefono_ref_1 = '';
    this.clientStudyForm.correo_ref_1 = '';
    this.clientStudyForm.lista_cuidad_ref_1 = '';
    this.department = '';
    this.selectedFirstDepartment = {ID: '', LABEL: ''};
    this.municipallity = {ID: '', LABEL: ''};
    this.municipalities = [];
    this.subject.changeFirstCommercialDepartment({ID: '', LABEL: ''});
    this.subject.changeFirstCommercialCity({ID: '', LABEL: ''});
    this.subject.changeStateFirstMunicipalities([]);
  }

  changeTypeNumber() {
    this.clientStudyForm.telefono_ref_1 = '';
    this.maxLengthNumber = (this.clientStudyForm.typeContactRef === 'cellphone') ? 10 : 7;
  }

  deletePerson() {
    this.clientStudyForm.tipo_ref_2 = '';
    this.clientStudyForm.nombre1_ref_2 = '';
    this.clientStudyForm.nombre2_ref_2 = '';
    this.clientStudyForm.apellido1_ref_2 = '';
    this.clientStudyForm.apellido2_ref_2 = '';
    this.clientStudyForm.telefono_ref_2 = '';
    this.clientStudyForm.correo_ref_2 = '';
    this.clientStudyForm.lista_cuidad_ref_2 = '';
    
    /* if (this.dataRadio.length === 1) {
      this.dataRadio[0] = radio;
      return;
    } */
    this.counterForm.splice((this.counterForm.length - 1), 1);
    this.handleValidateCommercialReference();
  }

  onBlur($event) {
    this.valueChanges.emit();
  }

  ngDoCheck() {
    this.formValidate();
  }

  formValidate() {
    /* const isFormValid = this.formReference.valid;
    this.formValid.emit(isFormValid); */
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(
      (Item) => Item.value === this.radioSelected
    );
    return radioSel.value;
  }

  firstRefDepartmentChanged(firstRefDepartmentId) {
    this.spinner.show();
  }

  handleValidateCommercialReference() {
    
    setTimeout(() => {
      const valid = this.formCommercialReference.form.valid;
      this.formCommercialReferenceValidate.emit(valid);
    }, 1);
  }
}
