import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '@components/components.module';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ModalLogoutComponent } from '@pages/shared/modals/modal-logout/modal-logout.component';
import { ChartsModule } from 'ng2-charts';
import { EmptySearchComponent } from '@pages/shared/empty-search/empty-search.component';
import { TableModule } from '@app/tables/table/table.module';
import { ModalModule } from '@app/modals/modal.module';
// redux
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AffiliateQuotaPipe } from './affiliate-quota.pipe';
import { AffiliateQuotaComponent } from './affiliate-quota/affiliate-quota.component';
import { FormAffiliateQuotaComponent } from './affiliate-quota/form-affiliate-quota/form-affiliate-quota.component';
import { ClientStudyComponent } from './client-study/client-study.component';
import { DisplayLegalPersonComponent } from './client-study/display-legal-person/display-legal-person.component';
import { DisplayNaturalPersonComponent } from './client-study/display-natural-person/display-natural-person.component';
import { AddressInputComponent } from './client-study/address-input/address-input.component';
import { ContactChannelsComponent } from './client-study/contact-channels/contact-channels.component';
import { OwnerInformationFormComponent } from './client-study/owner-information-form/owner-information-form.component';
import { CommercialReferenceComponent } from './client-study/commercial-reference/commercial-reference.component';
import { AcceptTermsComponent } from './client-study/accept-terms/accept-terms.component';
import { PersonTypeComponent } from './client-study/person-type/person-type.component';
import { ReferencesComponent } from './client-study/references/references.component';
import { PartnerInformationComponent } from './client-study/partner-information/partner-information.component';
import { CounterpartTableFormComponent } from './client-study/counterpart-table-form/counterpart-table-form.component';
import { ContrapartKnowledgeComponent } from './client-study/contrapart-knowledge/contrapart-knowledge.component';
import { CounterpartKnowledgeComponent } from './client-study/counterpart-knowledge/counterpart-knowledge.component';
import { ForeignCurrencyComponent } from './client-study/foreign-currency/foreign-currency.component';
import { CounterpartKnowledgePepComponent } from './client-study/counterpart-knowledge-pep/counterpart-knowledge-pep.component';
import { FirstLetterUpperCasePipe } from '@app/pipes/first-letter-upper-case.pipe';


@NgModule({
  declarations: [
    ModalLogoutComponent,
    EmptySearchComponent,
    AffiliateQuotaPipe,
    FirstLetterUpperCasePipe,
    AffiliateQuotaComponent,
    FormAffiliateQuotaComponent,
    ClientStudyComponent,
    DisplayLegalPersonComponent,
    DisplayNaturalPersonComponent,
    AddressInputComponent,
    ContactChannelsComponent,
    OwnerInformationFormComponent,
    CommercialReferenceComponent,
    AcceptTermsComponent,
    PersonTypeComponent,
    ReferencesComponent,
    PartnerInformationComponent,
    CounterpartTableFormComponent,
    ContrapartKnowledgeComponent,
    CounterpartKnowledgeComponent,
    ForeignCurrencyComponent,
    CounterpartKnowledgePepComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TableModule,
    ModalModule,
    AppRoutingModule,
    SharedModule,
    ChartsModule,
    FormsModule,
    HttpClientModule,
    // RouterModule.forRoot([])
  ],
  exports: [
    ModalLogoutComponent,
    EmptySearchComponent,
  ],
})
export class PagesModule {}
