import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { FetchService } from '@app/services/fetch.service';
import { PromissoryNoteServiceService } from '@app/services/promissory-note-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { ValidateResultsResponse, ValidateResultsResponseDataModel } from '@app/models/validateResult.model';
import { ReconoserIdService } from '@app/services/reconocer-id.service';

// tipoValidacion:
// 1: ninguno,
// 2: sms,
// 3: whatapp,
// 4: ambos

export type ValidationTypes = '1' | '2' | '3' | '4';

export interface ReconoserRequest {
  tipoValidacion: ValidationTypes;
  tipoDoc: string;
  numDoc: string;
  celular: string;
  email: string;
}

export interface ReconoserResponse {
  ok: boolean;
  message: string;
  data: ReconoserResponseModel;
  status: number;
}

export interface ReconoserResponseModel {
  code: number;
  data: DataReconoserModel;
  codeName: string;
}

export interface DataReconoserModel {
  procesoConvenioGuid: string;
  url: string;
  estadoProceso: number;
  guidCiudadano: string;
}

export interface IMessage {
  for: string;
  proccessCode: string;
  isSuccess: boolean;
  errors: any[];
}

export interface IResultData {
  guidConv: string;
  procesoConvenioGuid: string;
  guidCiu: string;
  primerNombre: string;
  segundoNombre: string;
  primerApellido: string;
  segundoApellido: string;
  infCandidato: string;
  tipoDoc: string;
  numDoc: string;
  email: string;
  celular: string;
  asesor: null;
  sede: null;
  nombreSede: null;
  codigoCliente: null;
  ejecutarEnMovil: boolean;
  estadoProceso: number;
  finalizado: boolean;
  estadoForense: null;
  validacion: boolean;
  fechaRegistro: Date;
  fechaFinalizacion: Date;
  fechaExpedicion: Date;
  lugarExpedicion: string;
  biometrias: null;
}

@Component({
  selector: 'app-validate-identity',
  templateUrl: './validate-identity.component.html',
  styleUrls: ['./validate-identity.component.scss']
})
export class ValidateIdentityComponent implements OnInit, OnDestroy {

  @ViewChild('iframe') iframe: ElementRef;
  public url: SafeResourceUrl;
  public link = '';
  public reconoserRequest: ReconoserRequest;
  public stopListening: Function;
  public resultData: IMessage | null;
  public origin: string = 'http://demorcs.olimpiait.com:6319';

  public showApp: boolean = false;

  @Input() userData: ReconoserRequest;
  @Output() onResultData: EventEmitter<IMessage> = new EventEmitter<IMessage>();
  @Output() validationIdentityOut: EventEmitter<ValidateResultsResponseDataModel> = new EventEmitter<ValidateResultsResponseDataModel>();

  constructor(
    private domSanitizer: DomSanitizer,
    private reconoserIdService: ReconoserIdService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    public router: Router,
    private promissoryNoteService: PromissoryNoteServiceService,
    private fetch: FetchService
  ) {
    this.handleStop();
    // this.fetch.clearStorage();
  }

  ngOnInit(): void {
    this.handleIframeData(this.userData);
    // Metodo Para probar con data quemada
  }

  ngOnDestroy() {
    this.stopListening();
  }

  handleIframeData(dataForm?: any): void {
    this.spinner.show();
    this.handleSetRequest(dataForm);
    this.reconoserIdService.getIframeUrl(dataForm)
      .subscribe((response: ReconoserResponse) => {
        console.log('face id response', response);
        const { ok, data } = response;
        const { url } = data.data;
        this.handleInitUrl(url);
      },
        (error: any) => {
          console.log(error);
          this.url = '';
          this.link = '';
          this.spinner.hide();
          const message = error.status === 400 ? 'Favor verifique los datos ingresados' : 'Inténtelo nuevamente';
          
          Swal.fire(
            {
              type: 'error',
              title: 'No fue posible iniciar el proceso',
              text: message
            }
          );
          this.validationIdentityOut.emit(null);
        });
  }

  handleSetRequest(data?: any): void {
    const mockRequest = {
      tipoValidacion: '1',
      tipoDoc: 'CC',
      numDoc: '1057546191',
      celular: '3115736275',
      email: 'j8@gmail.com'
    };

    this.reconoserRequest = data ? data : mockRequest;
  }

  handleInitUrl(url: string): void {
    this.link = url;
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.link);
  }

  handleEventMessage(event: Event) {
    const message: any = event;
    this.handleProcessMessageData(message);
    if (message.origin !== this.origin) return;
    console.log('message', message.data);
  }

  handleProcessMessageData(message: any) {
    if (message.data && message.data.for === 'resultData') {
      this.resultData = message.data;
      const status: string = this.resultData.isSuccess ? 'success' : 'error';
      console.log('message result data', message.data, status);

      if (this.resultData.isSuccess) {
        this.validateResultsWithProcessCode(this.resultData.proccessCode || '');
      }

    } else {
      // console.log('message null', message.data);
      this.resultData = null;
    }
    this.onResultData.emit(this.resultData);
  }

  handleStop() {
    this.stopListening = this.renderer.listen('window', 'message', this.handleEventMessage.bind(this));
  }

  onLoaded($event: any) {
    console.log('onLoaded', $event);
    this.spinner.hide();
  }

  validateResultsWithProcessCode(processCoded: string) {
    this.spinner.show();
    this.promissoryNoteService.validateResults(processCoded).subscribe(
      (resp: ValidateResultsResponse) => {
        const userInfoToSave: ValidateResultsResponseDataModel = resp.data.data || {} as ValidateResultsResponseDataModel;

        // Aprobó y finalizó la validación biometrica
        if (userInfoToSave.aprobado && userInfoToSave.finalizado) {
          this.fetch.saveUserInfo(userInfoToSave);
          this.validationIdentityOut.emit(userInfoToSave);
        }
      },
      (error: any) => {
        console.error('Ocurrió al realizar la validación de resultados', error);
        Swal.fire(
          {
            type: 'error',
            title: 'No fue posible iniciar el proceso',
            text: 'Inténtelo nuevamente'
          }
        );
        this.validationIdentityOut.emit(null);
      },
      () => {
        this.spinner.hide();
      }
    )
  }

}
