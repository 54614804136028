export const environment = {
  app: 'CoviFactura',
  urlApi: 'https://desarrollo11.c0v.co/api/covifacturapi/servicios',
  apiUrl: 'https://dev.node.covi.pw/respaldos/api/v1',
  apiUrlNit: 'https://admincovi.c0v.co',
  apiUrlBuyer: 'https://dev.node.covi.pw/buyerInt',
  apiNitToken: 'CLIENT_APP-312128avrut:aVesqt325@mqs',
  apiUrlPayzen: 'http://200.41.76.26:8080/PayzenCorporativo/servicios',
  urlEndpoint:
    'https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos',
  production: false,
};